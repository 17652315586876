<template>
  <common-dialog
    :show="show"
    @close="close"
  >
    <template #header>
      <span>Копировать документ</span>
    </template>
    <template #footer>
      <div class="d-flex justify-end mt-4">
        <!--<v-btn
          elevation="0"
          color="#EDEDED"
          class="mr-2"
          @click="unset({cancel:true})"
        >
          Отмена
        </v-btn>-->
        <v-btn
          color="#5CB7B1"
          elevation="0"
          dark
          @click="submit"
        >
          Копировать
        </v-btn>
      </div>
    </template>
  </common-dialog>
</template>
<script>
import closeOnEscape from '@/mixins/close-on-escape.mixin.js'
import CommonDialog from "@/components/shared/common-dialog.vue";

export default {
  components:{
    CommonDialog
  },
  mixins:[closeOnEscape],
  props:{
    show:{
      required:true,
      type:Boolean
    },
    id:{
      type:Number,
      required:false,
      default: -1
    }
  },
  data:() => ({
    loading:false,
  }),
  computed:{
    height(){
      return window.innerHeight - 500
    },
  },
  methods:{
    submit(){
      this.loading = true
      const [,,id] = this.$route.path.split('/')
      this.$store.dispatch('ptd/copyDocument',{
        id: this.id > 0 ? this.id : id, // this.id передается через пропс напр. в журнале
        // options
      }).then((res) => {
        this.loading = false
        const id = res.data
        this.$snackbar({text:`Документ скопирован. Новый документ: ${id}`, color:"green", top:false, right:false, timeout:10000})
        // Обновление журнала через событие сокета !
        this.close()
      }).catch(err => {
        this.loading = false
        this.$snackbar({text: err.response || "Ошибка",color:"red", top:false, right: false});
      })
    },    
    close(){
      this.$emit('update:show', false)
    }
  }
}
</script>
<style scoped>
.fit-content{
    width:fit-content
}
</style>
